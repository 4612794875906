import Link from 'next/link';
import React from 'react';
import '../styles/variables.scss'
import '../styles/globals.scss'
import { ScreenClassProvider } from 'react-grid-system'
import Head from 'next/head'

import { TYPEKIT_CSS_URL } from '~constants'
import { useRouter } from 'next/router';


export default function MyApp({Component, pageProps}) {
  
  const router = useRouter();
  const path = (/#!(\/.*)$/.exec(router.asPath) || [])[1];
  if (path) {
    router.replace(path);
  }
  
  return (
    <ScreenClassProvider>
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1" />
      <title>Sphere</title>
    </Head>
    <link rel="stylesheet" href={TYPEKIT_CSS_URL} type="text/css" />
    <Component {...pageProps} />
  </ScreenClassProvider>
  );
}